var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('button',{staticClass:"w-10 h-10 my-2 p-0 flex items-center justify-center rounded-lg relative",class:{
      'bg-black-800 text-black-50 hover:bg-black-700':
        _vm.isNotificationPanelActive,
      'text-black-600 hover:bg-black-700':
        !_vm.isNotificationPanelActive,
    },on:{"click":_vm.openNotificationPanel}},[_c('fluent-icon',{class:{
        'text-woot-500': _vm.isNotificationPanelActive,
      },attrs:{"icon":"alert"}}),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"text-black-900 bg-yellow-300 absolute -top-0.5 -right-1 text-xxs min-w-[1rem] rounded-full"},[_vm._v("\n      "+_vm._s(_vm.unreadCount)+"\n    ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }